.Table-Container {
    padding-right: 100px;
}

.table-header-rotated {
    border-collapse: collapse;
    padding-right: 100px;
}

.table-header-rotated td {
    width: 30px;
}

.table.table-header-rotated td {
  text-align: center;
  padding: 10px 5px;
  border: 1px solid #ccc;
  font-size: 10px;
  vertical-align: middle;
}

.table-header-rotated th.rotate {
  height: 140px;
  white-space: nowrap;
}

.table-header-rotated th.rotate > div {
  transform: translate(25px) rotate(315deg);
  width: 30px;
}

.table-header-rotated th.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

.table-header-rotated th.row-header {
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
  vertical-align: middle;
}

.Table-na {
    color: #bbb;
}

.Table-Cell-HasComment {
    background-color: aliceblue;
}

.table.table-header-rotated th {
    font-size: 12px;
}

.Table-Cell-Tooltip {
    opacity: .9;
}

.Table-Cell-Tooltip-Text {
    width: 300px;
    line-height: 1.4em;
}
