.Brand {
  font-size: 1.5rem;
  font-family: "Open Sans";
}

.Brand a.Brand-Link {
  font-weight: bold;
  color: var(--gray-400);
  transition: var(--transition);
  text-decoration: none;
}

.Brand .Brand-Link:hover {
  border-bottom: 0;
  color: var(--gray-500);
}
