.Overview-BackgroundItemList {
  margin-top: 2.5rem;
}

.Overview-NestedList {
  margin-top: 0.5rem;
}

.Overview-Amplification-Final {
  margin-bottom: 3rem;
}

p.Overview-Thanks {
  font-style: italic;
  margin-top: 4rem;
}
