:root {
  --font-header: "Graphik Medium", sans-serif;
  --font-sans: "Inter", sans-serif;
  --white: #fefefe;
  --gray-100: #f3f3ef;
  --gray-200: #e1e2d9;
  --gray-300: #8d9390;
  --gray-400: #5f6568;
  --gray-500: #363f49;
  --gray-600: #212b37;
  --black: #17202a;
  --darkest-blue: #007d96;
  --dark-blue: #139caf;
  --blue: #45aab7;
  --light-blue: #82c3c9;
  --lightest-blue: #d6ebe8;
  --dark-berry: #671341;
  --berry: #8f3969;
  --light-berry: #c793ae;
  --lightest-berry: #f1e3e9;
  --font-4xl: clamp(3.25rem, 6vw, 4.25rem);
  --font-3xl: clamp(2.5rem, 4.666666vw, 3.25rem);
  --font-2xl: clamp(1.75rem, 3vw, 2.25rem);
  --font-xl: clamp(1.5rem, 2.857142vw, 2rem);
  --font-lg: clamp(1.25rem, 2.142857vw, 1.5rem);
  --font-md: clamp(1.15rem, 1.785714vw, 1.25rem);
  --font-base: clamp(1rem, 1.642857vw, 1.15rem);
  --font-sm: clamp(0.9rem, 1.428571vw, 1rem);
  --font-xs: clamp(0.85rem, 1.214286vw, 0.9rem);

  --leading-tight: 1.2;
  --leading-snug: 1.5;
  --leading-loose: 1.75;
  --transition: all 0.3s ease-in-out;
  --light-shadow: 20px 20px 60px #dbd7d68e, -20px -20px 60px #ffffff87;
}

@font-face {
    font-family: 'Graphik Medium';
    src: url('/fonts/Graphik-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik Light';
    src: url('/fonts/Graphik-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik Light Italic';
    src: url('/fonts/Graphik-LightItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik Regular';
    src: url('/fonts/Graphik-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik Regular Italic';
    src: url('/fonts/Graphik-RegularItalic.otf') format('opentype');
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--font-sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--darkest-blue);
  transition: var(--transition);
}

a:hover {
  text-decoration: none;
  color: var(--berry);
}

@media print {
  a[href]:after {
    content: none;
  }
  .BlogPostComments,
  .Next,
  .Footer,
  .Breadcrumbs,
  .NavigationRight {
    display: none;
  }
  blockquote p:last-child {
    margin-bottom: 10px;
  }
}

/* Remove top margin for headers stacked on top of one another */
h1 + h2,
h1 + h3,
h1 + h4,
h2 + h2,
h2 + h3,
h2 + h4,
h3 + h2,
h3 + h3,
h3 + h4,
h4 + h2,
h4 + h3,
h4 + h4,
h1 + h5,
h2 + h5,
h3 + h5,
h4 + h5 {
  margin-top: 0.5rem !important;
}
