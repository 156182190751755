.Footer {
  background: var(--black);
}

.FooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5rem;
}

.RightContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 1rem;
}

.LinkList a {
  font-family: var(--font-sans);
  color: var(--gray-300);
  font-size: var(--font-sm);
  font-weight: 500;
  transition: var(--transition);
  position: relative;
  top: 0;
}

.LinkList a:hover {
  text-decoration: none;
  color: var(--gray-100);
}

.LinkList a:before,
.LinkList a:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: transparent;
  transition: var(--transition);
}

.LinkList a:hover:before,
.LinkList a:active:before {
  left: -16px;
  background-color: var(--gray-300);
}

.LinkList {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
}

.LinkList a.active {
  text-decoration: none;
  color: var(--blue);
}

.LinkList a.active:before,
.LinkList a.active:after {
  content: "";
  display: block;
  position: absolute;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--blue);
}

.Location {
  display: flex;
  flex-direction: row;
  grid-gap: 0.75rem;
}

.Location p {
  color: var(--gray-300);
  font-size: var(--font-sm);
  margin: 0;
}

.Location p:first-child {
  color: var(--gray-200);
  margin-bottom: 0.25rem;
}

.LocationSocial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LocationSocial .social {
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  margin-left: 2rem;
}

a.SocialLink svg {
  fill: var(--gray-400);
  transition: var(--transition);
}

a.SocialLink svg:hover {
  fill: var(--gray-200);
}

@media (max-width: 660px) {
  .FooterContainer {
    flex-direction: column;
    padding: 2rem 1.5rem;
    grid-gap: 0.5rem;
  }
  .RightContainer {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 3rem;
  }
  .LocationSocial {
    grid-gap: 1rem;
  }
  .LeftContainer {
    padding: 0.5rem 1rem;
  }
}
