.TextWithPointers-Pointer {
    border-bottom: 1px dotted #666;
    cursor: default;
}

.TextWithPointers-Pointer-Tooltip {
    opacity: .9;
}

.TextWithPointers-Pointer-Tooltip-Text {
    width: 400px;
    font-size: 12px;
    line-height: 1.4em;
}

.TextWithPointers-Pointer-Tooltip-Text-Action {
    font-family: "Lucida Console", Monaco, monospace;
    color: #ff0;
}

.TextWithPointers-Pointer-Tooltip-Pointer {
    color: #ff0;
}
