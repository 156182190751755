.NavigationRight {
  float: right;
  margin: 0;
  position: relative;
  top: 0;
}

.NavigationRight a {
  margin-left: 1em;
  color: var(--gray-400);
  font-weight: 500;
  font-family: var(--font-sans);
  font-size: var(--font-sm);
  transition: var(--transition);
  padding: 0.4rem 0.75rem;
  border-radius: 5rem;
  position: relative;
  top: 0;
}

.NavigationRight a:before,
.NavigationRight a:after {
  content: "";
  display: block;
  position: absolute;
  width: 38px;
  height: 2px;
  background-color: transparent;
  transition: var(--transition);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.NavigationRight a:hover:before,
.NavigationRight a:hover:after {
  background-color: var(--gray-300);
  transform: translate(-50%, 100%);
}

.NavigationRight a:hover {
  color: var(--black);
}

.NavigationRight a:focus {
  outline: none;
}

.NavigationRight-Toggle {
  display: none;
}

.NavigationRight a.active {
  text-decoration: none;
  color: var(--darkest-blue);
}

.NavigationRight a.active:before,
.NavigationRight a.active:after {
  background-color: var(--blue);
  transform: translate(-50%, 100%);
}

@media (max-width: 650px) {
  .NavigationRight-MediaQuery-Large {
    /* if media query says large but css says small, don't show menu */
    display: none;
  }
  .NavigationRight-Links {
    position: absolute;
    top: 2rem;
    right: 0.25rem;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0px 6px 24px rgba(60, 54, 53, 0.136);
    padding: 1rem 0.25rem;
    width: 200px;
    z-index: 99;
  }
  .NavigationRight-Links a {
    display: block;
  }
  .NavigationRight-Links a:hover {
    color: var(--berry);
  }
  .NavigationRight-Toggle {
    display: block;
  }
  .NavigationRight a.active:before,
  .NavigationRight a.active:after,
  .NavigationRight a:before,
  .NavigationRight a:after,
  .NavigationRight a:hover:before,
  .NavigationRight a:hover:after {
    background-color: none;
    display: none;
  }
}
