.Image {
    margin: 3em 0 2em 0;
}

.Image img {
    width: 100%;
    margin-bottom: 1em;
}

.Image-Caption {
    color: #aaa;
    font-size: smaller;
    text-align: center;
    line-height: 1.4em;
}