.TableOfContents {
  padding: 5rem 2rem 2rem 0.25rem;
  overflow-x: scroll;
  max-height: 95vh;
}

.TableOfContents ol {
  margin-bottom: 0.75rem;
  padding-inline-start: 1.5rem;
}

.TableOfContents li {
  margin-bottom: 0.25rem;
  transition: var(--transition);
  list-style-type: none;
  padding-left: 0;
  position: relative;
  top: 0;
}

.TableOfContents li + li {
  margin-bottom: 0.5rem;
}

.TableOfContents ol {
  margin-top: 0.5rem;
}

.TableOfContents li a {
  color: var(--gray-400);
}

.TableOfContents li a:hover {
  color: var(--dark-blue);
}

.TableOfContents li a:focus {
  color: var(--berry);
}

.TableOfContents-Header {
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.025rem;
  font-size: var(--font-xs);
  color: var(--gray-500);
}

@media (max-width: 1280px) {
  .TableOfContents {
    display: none;
  }
}
