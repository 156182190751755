.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--white);
}

.App-Main {
  flex: 1 0 auto;
  background: var(--gray-100);
}
