.Article {
  position: relative;
  top: 0;
}

.tocContainer {
  position: sticky;
  top: 0;
  left: 1.5rem;
  max-width: 280px;
  float: left;
  margin-right: -300px;
}
