.home-container {
  background: var(--gray-100);
}

.section {
  padding: 4rem 0 6rem;
  font-size: var(--font-base);
  z-index: 2;
}

.section .container {
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.section p {
  max-width: 50ch;
}

.eyebrow {
  font-size: var(--font-xs);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: var(--berry);
  font-weight: 600;
}

.section h2 {
  font-size: var(--font-3xl);
  font-weight: 600;
}

/* Main banner section */

.HomeBanner {
  display: flex;
  flex-direction: column;
  padding: 6rem 1.5rem 8rem;
  align-items: center;
  z-index: 2;
}

.BannerContent {
  width: 1300px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 2rem;
  z-index: 2;
}

.BannerHeading {
  font-family: var(--font-header);
  color: var(--gray-500);
  line-height: 100%;
  font-size: clamp(4rem, 7vw, 8rem);
  padding-bottom: 2rem;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
  max-width: 12ch;
}

.BannerContent p {
  color: var(--gray-400);
  font-size: var(--font-base);
  line-height: var(--leading-snug);
  font-weight: 400;
  margin: 0 auto 1rem;
  max-width: 56ch;
  z-index: 2;
  text-align: center;
}

.highlight {
  background: var(--lightest-blue);
  color: var(--black);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
}

.Home-Banner-More {
  background: var(--white);
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 5rem;
  margin: 3rem auto 0;
  transition: var(--transition);
  color: var(--dark-blue);
  font-weight: 600;
  font-size: var(--font-sm);
  box-shadow: 0px 2px 7px #dbd7d68e, -10px -10px 40px #ffffff87;
}

.Home-Banner-More:hover {
  box-shadow: 10px 10px 30px #dbd7d68e, -10px -10px 50px #ffffff87;
  cursor: pointer;
  color: var(--berry);
}

/* Elicit Section */

.elicit {
  background: linear-gradient(var(--gray-100) 0%, var(--white) 100%);
}

.elicit .container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 1330px;
  max-width: 100%;
}

.elicit button {
  border: none;
  background: var(--white);
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 5rem;
  margin: 2rem auto 0;
  transition: var(--transition);
  color: var(--dark-blue);
  font-weight: 600;
  font-size: var(--font-sm);
  box-shadow: 0px 2px 7px #dbd7d68e, -10px -10px 40px #ffffff87;
  margin-right: 1.5rem;
}

.elicit button:hover {
  box-shadow: 10px 10px 30px #dbd7d68e, -10px -10px 50px #ffffff87;
  cursor: pointer;
  color: var(--berry);
}

.elicit .elicitSummary {
  max-width: 38%;
  margin-right: 4rem;
}

a.whyElicit {
  font-size: var(--font-sm);
  color: var(--dark-blue);
  font-weight: 600;
  position: relative;
  top: 0;
}

a.whyElicit:before,
a.whyElicit:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 0;
  height: 2px;
  background-color: var(--berry);
  transition: all 0.5s ease-in-out;
}

a.whyElicit:before {
  left: 0;
}

a.whyElicit:hover {
  text-decoration: none;
  color: var(--berry);
}

a.whyElicit:hover:before {
  width: 100%;
}

.elicit h2 {
  font-family: "Graphik Regular";
}

.elicit .elicitVideo {
  position: relative;
  height: calc(66vw / 2);
}

.elicit .elicitVideo img {
  position: absolute;
  width: 56vw;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  box-shadow: 0px 2px 8px 0px rgba(122, 113, 100, 0.15);
}

@media (max-width: 880px) {
  .elicit .container {
    flex-direction: column;
    grid-gap: 2rem;
  }
  .elicit .elicitSummary {
    max-width: 100%;
  }
  .elicit .elicitVideo {
    position: static;
    height: fit-content;
  }
  .elicit .elicitVideo img {
    position: static;
    width: 100%;
  }
}

/* Work with us section */

.workwithus {
  width: 100%;
  background: var(--white);
  padding: 6rem 0;
}

.workwithus .container {
  width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workwithus h2 {
  margin-top: 0.25rem;
  font-family: "Graphik Regular";
}

.jobs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 2rem;
  grid-gap: 3rem;
}

@media (max-width: 1000px) {
  .jobs {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

@media (max-width: 600px) {
  .jobs {
    grid-template-columns: 1fr;
  }
}

/* Blog updates section */

.blog .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
  margin: 3rem auto;
}

.blog .eyebrow {
  grid-column: 1;
}

div.FeaturePost {
  grid-column: 1;
  padding: 0.25rem 1.5rem 1rem;
  border-radius: 0.5rem;
  margin-left: -1.5rem;
  transition: var(--transition);
  height: fit-content;
}

div.FeaturePost:hover {
  background-color: #ffffff7f;
  box-shadow: var(--light-shadow);
}

.FeaturePost h2 {
  color: var(--gray-500);
  font-family: "Graphik Regular";
}

.SecondaryPosts h3 {
  color: var(--gray-500);
  font-family: var(--font-header);
  margin-top: 1rem;
}

.FeaturePost p,
.SecondaryPosts p {
  color: var(--gray-400);
  font-weight: 400;
}

.SecondaryPosts {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.SecondaryPostItem {
  padding: 0.25rem 1.5rem 0.5rem;
  border-radius: 0.5rem;
  margin-left: -1.5rem;
  transition: var(--transition);
}

.SecondaryPostItem:hover {
  background-color: #ffffff7f;
  box-shadow: var(--light-shadow);
}

.SecondaryPostItem:hover h3,
div.FeaturePost:hover h2 {
  color: var(--berry);
}

@media (max-width: 728px) {
  .blog .container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

/* Donate section */

.donate {
  background: var(--light-blue);
  padding: 5rem 0;
}

.donate h2 {
  font-family: "Graphik Regular";
}

.donate .container::before,
.donate .container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.donate .container {
  display: flex;
  flex-direction: row;
  width: 1350px;
  max-width: 100%;
  align-items: center;
}

.LeftDonate {
  display: flex;
  grid-gap: 0.5rem;
}

.LeftDonate svg {
  margin: 1rem;
  transform: rotate(180deg);
}

.DonateButton {
  background: var(--white);
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 5rem;
  transition: var(--transition);
  color: var(--dark-blue);
  font-weight: 600;
  font-size: var(--font-sm);
  box-shadow: 0px 2px 4px #638da3a3;
  margin-left: 7rem;
}

@media (max-width: 800px) {
  .donate {
    padding: 2rem 0;
  }
  .donate .container {
    flex-direction: column;
    grid-gap: 1rem;
  }

  .LeftDonate svg {
    margin: 0.5rem;
  }
  .DonateButton {
    margin-left: 0;
  }
}
