.Donate a#donate-with-crypto {
  color: #fff !important;
  font-weight: 500;
  font-size: var(--font-base);
  background: var(--blue) !important;
  box-shadow: 1px 0px 2px #dbd7d68e, -10px -10px 40px #ffffff87 !important;
  border: none !important;
  border-radius: 8px !important;
  margin: 2rem auto !important;
  padding: 1rem 1.5rem !important;
  display: block !important;
  max-width: 420px;
}

.Donate a#donate-with-crypto:hover {
  text-decoration: none !important;
  background: var(--dark-blue) !important;
}

.Donate {
  padding-bottom: 8rem;
}
