.Next {
  width: 800px;
  max-width: 100%;
  margin: 2rem auto 0;
  background: var(--white);
  padding: 5rem 0 4rem;
}

.InnerContainer {
  background: var(--berry);
  border-radius: 0.75rem;
  width: 100%;
  padding: 2.5rem 3rem;
  text-align: left;
  font-size: var(--font-sm);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  transition: var(--transition);
  box-shadow: 0px 2px 2px 0px rgba(195, 53, 131, 0);
}

.InnerContainer:hover {
  box-shadow: 0px 8px 28px 0px rgba(222, 30, 129, 0.367);
}

.InnerContainer:hover svg {
  fill: #ffede7;
  transform: translateX(2px);
}

.InnerContainer > div {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.InnerContainer svg {
  flex-shrink: 0;
  fill: var(--light-berry);
  transition: var(--transition);
}

.ReadNext {
  color: var(--light-berry);
  font-size: var(--font-sm);
  font-weight: 500;
}

.NextTitle {
  font-size: var(--font-lg);
  color: var(--white);
  font-family: "Graphik Regular";
}

@media (max-width: 800px) {
  .Next {
    padding: 3rem 1rem;
  }
}
