.Elicit .elicitVideo {
  position: static;
  grid-column: feature;
  margin: 2rem auto;
}

.Elicit .elicitVideo img {
  position: static;
  width: 1000px;
  max-width: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--gray-200);
  box-shadow: 0px 2px 8px 0px rgba(122, 113, 100, 0.15);
}

.Elicit p:first-child {
  margin-top: 1rem;
}
