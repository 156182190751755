.BlogPostPage {
  background: linear-gradient(var(--gray-100) 0%, var(--white) 30%);
}

.BlogPostBodyContainer {
  padding: 4rem 0 1rem;
}

.BlogMetadata,
.BlogPostBody {
  --gap: clamp(1rem, 6vw, 3rem);
  --full: minmax(var(--gap), 1fr);
  --content: min(80ch, 100% - var(--gap) * 2);
  --popout: minmax(0, 2rem);
  --feature: minmax(0, 5rem);

  display: grid;
  grid-template-columns:
    [full-start] var(--full)
    [feature-start] var(--feature)
    [popout-start] var(--popout)
    [content-start] var(--content) [content-end]
    var(--popout) [popout-end]
    var(--feature) [feature-end]
    var(--full) [full-end];
}

.BlogMetadata > *,
.BlogPostBody > * {
  grid-column: content;
  width: 100%;
}

.popout {
  grid-column: popout;
}
.feature {
  grid-column: feature;
}
.full {
  grid-column: full;
}

.SummaryContainer {
  grid-column: popout;
  padding: 2rem 2.5rem;
  border-radius: 0.5rem;
  background: #ffffffab;
  margin: 2rem 0;
}

.SummaryContainer > p {
  font-size: var(--font-sm);
  font-weight: 600;
  color: var(--berry);
}

.BlogPostBody img,
.BlogPostBody video,
.BlogPostBody div.Image,
.BlogPostBody p.Image {
  grid-column: feature;
}

.Page-Title {
  margin: 0;
  width: 100%;
  font-size: var(--font-3xl);
  font-family: var(--font-header);
  margin-bottom: 1.25rem;
}

.BlogPostPage-Attribution {
  font-size: var(--font-sm);
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  grid-gap: 0.75rem;
  align-items: center;
  color: var(--gray-400);
}

.BlogPostPage-Attribution .divider {
  width: 6px;
  height: 6px;
  background: var(--gray-200);
  border-radius: 50%;
  flex-shrink: 0;
}

.BlogPostInline {
  margin-bottom: 5em;
}

.BlogPostBody h1,
.BlogPostBody h2,
.BlogPostBody h3 {
  font-family: var(--font-header);
  font-weight: 500;
}

.BlogSummary p,
.BlogSummary li,
.BlogSummary ol,
.BlogPostBody p,
.BlogPostBody li,
.BlogPostBody ol {
  font-size: var(--font-base);
  line-height: var(--leading-loose);
  margin: 0 auto 1.5rem;
}

.BlogPostBody img,
.BlogPostBody video,
.BlogPostBody div.Image,
.BlogPostBody p.Image,
.BlogPostBody a.Image {
  grid-column: feature;
  max-width: 100%;
  border-radius: 0.25rem;
  margin: 1rem 0;
}

.BlogPostBody h1 {
  color: var(--gray-600);
  font-size: var(--font-4xl);
  margin: 2rem auto 1rem;
}

.BlogPostBody h2 {
  color: var(--gray-500);
  font-size: var(--font-xl);
  margin: 3rem auto 1rem;
}

.BlogPostBody h3 {
  color: var(--gray-500);
  font-size: var(--font-lg);
  margin: 3rem auto 1rem;
}

.BlogPostBody h4 {
  color: var(--gray-400);
  font-size: var(--font-base);
  margin: 2rem auto 1rem;
}

.BlogPostBody h5 {
  color: var(--gray-400);
  font-style: italic;
  font-size: var(--font-base);
  margin: 2rem auto 1rem;
}

.BlogPostBody p > a,
.BlogPostBody li > a {
  color: var(--darkest-blue);
  font-weight: 500;
  transition: var(--transition);
  position: relative;
  display: inline-block;
}

.BlogPostBody p > a:not(:has(img)):before,
.BlogPostBody p > a:not(:has(img)):after,
.BlogPostBody li > a:not(:has(img)):before,
.BlogPostBody li > a:not(:has(img)):after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: var(--berry);
  transition: all 0.5s ease-in-out;
}

.BlogPostBody p > a:not(:has(img)):before,
.BlogPostBody li > a:not(:has(img)):before {
  left: 0;
}

.BlogPostBody p > a:not(:has(img)):hover,
.BlogPostBody li > a:not(:has(img)):hover {
  text-decoration: none;
  color: var(--berry);
}

.BlogPostBody p > a:not(:has(img)):hover:before,
.BlogPostBody li > a:not(:has(img)):hover:before {
  width: 100%;
}

.BlogPostBody blockquote {
  font-size: 1em;
  font-style: italic;
}
