.Presentation {
  --gap: clamp(1rem, 6vw, 3rem);
  --full: minmax(var(--gap), 1fr);
  --content: min(84ch, 100% - var(--gap) * 2);

  display: grid;
  grid-template-columns:
    [full-start] var(--full)
    [content-start] var(--content) [content-end]
    var(--full) [full-end];
}

.Presentation > * {
  grid-column: content;
  width: 100%;
}

.Slide {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.Slide-Number {
  text-align: center;
  margin-bottom: 1rem;
  font-family: var(--font-sans);
  font-weight: bold;
}

.Slide-Annotation {
  text-align: left;
  margin-top: 1.5rem;
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.Slide-Image {
  max-width: 100%;
  margin: 0 auto 2rem;
  display: block;
  box-shadow: var(--light-shadow);
}

.Slide-Image-Link {
  display: block;
}

h2.Slide-Title {
  margin-top: 3rem;
  display: block;
  text-align: center;
}

.Presentation-Summary {
  /* replace with summary CSS */
}
