.Header {
  width: 100%;
  max-width: 1300px;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

@media (max-width: 1299px) {
  .Header {
    padding: 1.5rem;
  }
}
