.Workspace {
    margin: 1.5em 0 1.5em 0;
    border: 1px solid #aaa;
}

.Workspace-Question {
    background-color: #c4dcf1;
}

.Workspace-Question, .Workspace-Body, .Workspace-Response {
    padding: .5em;
}

.Workspace-Body {
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    background-color: #f2f9ff;
}

.Workspace-Response {
    background-color: #f2f9ff;
}

.Workspace-Body-IsEmpty, .Workspace-Response-IsEmpty {
    color: #aaa
}
