.Team-Persons {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  margin: 2rem 0 2rem;
}

h2.collaboratorsTitle, h2.bodTitle {
  margin: 6rem 0 2rem;
}

.collaborators, .bod {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.joinUs {
  display: flex;
  align-content: center;
  grid-gap: 0.5rem;
  justify-content: center;
  background: var(--gray-100);
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--gray-400);
}

.joinUs a {
  color: var(--berry);
}

.joinUs a:hover {
  color: var(--dark-berry);
}

p.final {
    margin-bottom: 5rem;
}
