.BlogIndexPage {
  background: linear-gradient(var(--gray-100) 0%, var(--white) 30%);
}

.BlogIndexPage .Page-Title {
  font-size: var(--font-3xl);
  margin-top: 2rem;
}

.HeadlineSummaryPosts {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  transition: all 0.5 ease-in-out;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0 8rem;
}

.HeadlineSummaryPosts h1 {
  color: var(--gray-500);
  font-family: var(--font-header);
  font-weight: 500;
  margin: 0.5rem 0 1rem;
  font-size: var(--font-2xl);
}

.HeadlineSummaryPosts h1:hover {
  color: var(--black);
}

.BlogNewsletter {
  margin-top: 4em;
}

.SinglePost {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  transition: var(--transition);
}

.SinglePost svg {
  flex-shrink: 0;
  position: relative;
  top: 0.5rem;
  opacity: 25%;
  fill: var(--gray-300);
  transition: var(--transition);
}

.SinglePost:hover {
  background-color: #ffffff7f;
  box-shadow: var(--light-shadow);
}

.SinglePost:hover svg {
  fill: var(--blue);
  opacity: 100%;
}

.SinglePost:hover h1 {
  color: var(--black);
}

.HeadlineSummaryPosts p {
  color: var(--gray-400);
  font-size: 1rem;
}

.HeadlineSummaryPosts p:hover {
  text-decoration: none;
}
