.WorkspaceObservation:before, .WorkspaceElement-Observation:before {
    content: "→ ";
}

.WorkspaceAction, .WorkspaceObservation {
    padding: .3em;
    border-bottom: 1px solid #c4ddf1;
}

.WorkspaceElement {
    border-bottom: 1px solid #c4ddf1;
    padding: .5em;
}

.WorkspaceElement-IsLast, .WorkspaceElement-StandAlone, .WorkspaceAction-IsLast, .WorkspaceObservation-IsLast {
    border-bottom: none;
}

.WorkspaceElement-StandAlone {
    background-color: #f2f9ff;
    border: 1px solid #c4ddf1;
    margin: 1em 0 1em 0;
}

.WorkspaceElement-HasRegister {
    position: relative;
}

.WorkspaceElement-Register {
    position: absolute;
    font-weight: bold;
    color: #497ca9;
}

.WorkspaceElement-HasRegister .WorkspaceElement-Action, .WorkspaceElement-HasRegister .WorkspaceElement-Observation {
    margin-left: 30px;
}
