.Page {
  background: linear-gradient(var(--gray-100) 0%, var(--white) 25%);
  padding-top: 2rem;
}

.Page-Content h1,
.Page-Content h2,
.Page-Content h3 {
  font-family: var(--font-header);
  font-weight: 500;
}

.Page-Content p,
.Page-Content li,
.Page-Content ol,
.Page-Content ul {
  font-size: var(--font-base);
  line-height: var(--leading-loose);
  margin: 0 auto 1.25rem;
}

.Page-Content li {
  list-style-type: none;
  margin-bottom: 0.25rem;
  padding-left: 0.25rem;
  position: relative;
  top: 0;
}

.Page-Content li:before,
.Page-Content li:after {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: 16px;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--gray-200);
}

.Page-Content li:first-child {
  margin-top: 0.75rem;
}

.Page-Content h1 {
  color: var(--gray-600);
  font-size: var(--font-4xl);
  margin: 2rem auto 1rem;
  width: 740px;
  max-width: 100%;
}

.Page-Content h2 {
  color: var(--gray-500);
  font-size: var(--font-xl);
  margin: 3rem auto 1rem;
}

.Page-Content h3 {
  color: var(--gray-500);
  font-size: var(--font-lg);
  margin: 3rem auto 1rem;
}

.Page-Content h4 {
  color: var(--gray-400);
  font-size: var(--font-base);
  margin: 2rem auto 1rem;
}

.Page-Content h5 {
  color: var(--gray-400);
  font-style: italic;
  font-size: var(--font-base);
  margin: 2rem auto 1rem;
}

.Page blockquote {
  font-size: 1em;
  font-style: italic;
}

.Page-Content p > a,
.Page-Content li > a {
  color: var(--darkest-blue);
  font-weight: 500;
  transition: var(--transition);
  position: relative;
  display: inline-block;
}

.Page-Content p > a:not(:has(img)):before,
.Page-Content p > a:not(:has(img)):after,
.Page-Content li > a:not(:has(img)):before,
.Page-Content li > a:not(:has(img)):after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: var(--berry);
  transition: all 0.5s ease-in-out;
}

.Page-Content p > a:not(:has(img)):before,
.Page-Content li > a:not(:has(img)):before {
  left: 0;
}

.Page-Content p > a:not(:has(img)):hover,
.Page-Content li > a:not(:has(img)):hover {
  text-decoration: none;
  color: var(--berry);
}

.Page-Content p > a:not(:has(img)):hover:before,
.Page-Content li > a:not(:has(img)):hover:before {
  width: 100%;
}

@media (max-width: 800px) {
  .Page-Title {
    padding: 0 1.5rem;
  }
}

@media (max-width: 480px) {
  .Page-Content h1 {
    margin-top: 0.4em;
  }
  .Page-Content h1,
  .Page-Content h2,
  .Page-Content h3 {
    margin-bottom: 0.4em;
  }
  .Page-Content h2,
  .Page-Content h3 {
    margin-top: 1em;
  }
}
