.GridLayout {
  --gap: clamp(1rem, 6vw, 3rem);
  --full: minmax(var(--gap), 1fr);
  --content: min(84ch, 100% - var(--gap) * 2);
  --popout: minmax(0, 2rem);
  --feature: minmax(0, 5rem);

  display: grid;
  grid-template-columns:
    [full-start] var(--full)
    [feature-start] var(--feature)
    [popout-start] var(--popout)
    [content-start] var(--content) [content-end]
    var(--popout) [popout-end]
    var(--feature) [feature-end]
    var(--full) [full-end];
}

.GridLayout > * {
  grid-column: content;
  width: 100%;
}
.popout {
  grid-column: popout;
}
.feature {
  grid-column: feature;
}
.full {
  grid-column: full;
}

.GridLayout img,
.GridLayout video,
.GridLayout div.Image,
.GridLayout p.Image {
  grid-column: feature;
}
