.ImageItem {
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  .ImageItem {
    flex-direction: column;
  }
}

.ImageItem-Image,
.ImageItem-Image a,
.ImageItem-Image a img {
  width: 180px;
  border-radius: 0.25rem;
}

.ImageItem-Identifier {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  flex-wrap: wrap;
}

.ImageItem .metadata {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.ImageItem-Title a {
  font-size: var(--font-md);
  color: var(--darkest-blue);
  font-weight: 600;
  flex-shrink: 0;
}

.ImageItem .divider {
  width: 6px;
  height: 6px;
  background: var(--gray-200);
  border-radius: 50%;
  flex-shrink: 0;
}

.ImageItem-Subtitle {
  font-size: var(--font-md);
  color: var(--gray-500);
  flex-shrink: 0;
}

.ImageItem-Body {
  font-size: var(--font-sm);
  color: var(--gray-400);
  line-height: var(--leading-snug);
}
