.NewsletterForm {
  width: 100%;
  max-width: 100%;
  background: var(--gray-600);
  padding: 3rem 5rem;
  border-bottom: 1px solid #ffffff0d;
}

.NewsletterContainer {
  display: flex;
  flex-direction: row;
  font-family: "Graphik Regular", sans-serif;
  align-items: center;
  grid-gap: 2rem;
}

.NewsletterContainer h2 {
  color: var(--gray-100);
  margin: 0;
}

.NewsletterContainer .Left {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 1.25rem;
  flex-shrink: 0;
}

.NewsletterContainer .Left svg {
  transform: rotateZ(-15deg);
}

.NewsletterContainer hr {
  width: 100%;
  border: 1px solid #ffffff0d;
}

.NewsletterForm-Submit button {
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  background: var(--dark-blue);
  color: var(--white);
  border: none;
  font-weight: 600;
  text-shadow: none;
  font-size: var(--font-sm);
  box-shadow: 0px 2px 2px 0px rgb(27, 28, 33, 0);
  transition: all 0.2s ease-in-out;
}

.NewsletterForm-Submit button:hover {
  background: var(--blue);
  color: var(--white);
  box-shadow: 0px 8px 22px 0px rgb(27, 28, 33, 1);
}

.NewsletterContainer form {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.NewsletterContainer form input {
  background: none;
  border: 1px solid var(--gray-500);
  padding: 1.65rem 1.25rem;
  border-radius: 0.5rem;
  font-size: var(--font-sm);
  margin-right: 0.5rem;
  min-width: 400px;
  max-width: 100%;
  color: var(--gray-100);
}

@media (max-width: 1000px) {
  .NewsletterForm {
    padding: 2rem 1.5rem;
  }
  .NewsletterForm,
  .NewsletterContainer {
    max-width: 100%;
    flex-direction: column;
    align-items: start;
  }
  .NewsletterContainer .Left,
  .NewsletterContainer form {
    flex-shrink: 1;
  }
  .NewsletterContainer hr {
    display: none;
  }
  .NewsletterContainer form input {
    min-width: 90%;
  }
}
